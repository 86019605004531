import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import AMD from "../components/AMD";
import Topbar from "../components/Topbar";


const AMDPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Art, Music and Dance">
            <Topbar />
            <NavOne />
            <PageHeader title="Art, Music and Dance" />
            <AMD />
            <Footer />
        </Layout>
    );
};

export default AMDPage;
